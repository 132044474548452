<template>
    <div class="container" id="contact">
        <div class="row logo_partners mt-5">
            <div class="col-12 mb-5">
                <h3 class="text-center font_montserrat_bold">Główny organizator</h3>
                <div class="row">
                    <div class="col-6 text-center">
                        <img src="img/PCI.svg" data-aos="flip-right" class="aos-init aos-animate" style="width: 80%">
                    </div>
                    <div class="col-6 text-center">
                        <img src="img/PSLBiW.svg" data-aos="flip-right" class="aos-init aos-animate" style="width: 80%">
                    </div>
                </div>
            </div>
            <div class="col-12 text-center honorary_patrons">
                <h3 class="text-center font_montserrat_bold mb-3">Patronaty honorowe</h3>
                <div class="row">
                    <div class="col-6 col-lg-3">
                        <img src="img/wojewoda_podkarpacki.png" style="max-height: 170px;width: auto;">
                        <p>Ewa Leniart<br/>Wojewoda Podkarpacki</p>
                    </div>
                    <div class="col-6 col-lg-3">
                        <img src="img/wojewodztwo_podkarpackie.png" style="max-height: 170px;width: auto;">
                        <p>Władysław Ortyl<br/>Marszałek Województwa Podkarpackiego</p>
                    </div>
                    <div class="col-6 col-lg-3">
                        <img src="img/prezydent_miasta_rzeszow.png" style="max-height: 170px;width: auto;">
                        <p>Konrad Fijołek<br/>Prezydent Miasta Rzeszowa</p>
                    </div>
                    <div class="col-6 col-lg-3">
                        <img src="img/wojewodztwo_podkarpackie.png" style="max-height: 170px;width: auto;">
                        <p>Jerzy Borcz<br/>Przewodniczący Sejmiku Województwa Podkarpackiego</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row logo_partners mt-5">
            <div class="col-12">
                <h3 class="text-center font_montserrat_bold">Partnerzy</h3>
                <div class="row justify-content-center mt-5">
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/gum.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/pca.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/olm.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/eaa.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/pcbic.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/procarpathia.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/pwste.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/bcc.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/gcenergy.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/tuv.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/comparic24.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/rpkkrakow.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/bury.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/sieroslawski.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>
                    <div class="col-6 col-sm-4 col-md-4 col-lg-3 my-2">
                        <img src="img/lem2021.png" data-aos="flip-right" class="aos-init aos-animate">
                    </div>     
                </div>
            </div>
        </div>
        <div class="row contact px-5 my-5">
            <div class="col-12 col-md-2 d-flex align-items-center px-3">
                <h1>KONTAKT:</h1>
            </div>
            <div class="col-12 col-md mt-4 mb-4">
                <div class="row">
                    <div class="col contact-image">
                        <img src="img/pfnib_wf.JPG" alt="PFNiB Wojciech Fiksa">
                    </div>
                    <div class="col d-flex align-items-center pl-0">
                        <div class="contact-text">
                            <span>Sprawy organizacyjne</span>
                            <p>Wojciech Fiksa</p>
                            <p><a href="mailto:w.fiksa@pcinn.org">w.fiksa@pcinn.org</a></p>
                            <p><a href="tel:+48798870493">tel. +48 798 870 493</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md mt-4 mb-4">
                <div class="row">
                    <div class="col contact-image">
                        <img src="img/pfnib_as.JPG" alt="PFNiB Anna Smyk">
                    </div>
                    <div class="col d-flex align-items-center pl-0">
                        <div class="contact-text">
                            <span>Konferencja I-P-A</span>
                            <p>Anna Smyk</p>
                            <p><a href="mailto:laboratoria@pcinn.org">laboratoria@pcinn.org</a></p>
                            <p><a href="tel:+48504238246">tel. +48 504 238 246</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md mt-4 mb-4">
                <div class="row">
                    <div class="col contact-image">
                        <img src="img/pfnib_mb.JPG" alt="PFNiB Martyna Borczyk">
                    </div>
                    <div class="col d-flex align-items-center pl-0">
                        <div class="contact-text">
                            <span>Komunikacja i media</span>
                            <p>Martyna Borczyk</p>
                            <p><a href="mailto:m.borczyk@pcinn.org">m.borczyk@pcinn.org</a></p>
                            <p><a href="tel:+48572601241">tel. +48 572 601 241</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-3 mb-4">
                <a href="https://pcinn.org" target="_blank">
                    <img src="img/logo_pci_svg.svg" alt="Podkarpackie Centrum Innowacji" style="width: 100%;">
                </a>
            </div>
        </div>
        <div class="row footer mb-3">
            <div class="col-12 col-lg-6">
                <p class="font-weight-bold">Projekt Podkarpackie Centrum Innowacji Sp. z o.o.</p>
                <p>jest finansowany ze środków Europejskiego Funduszu</p>
                <p>Rozwoju Regionalnego na lata 2014-2020, do końca 2022r.</p>
                <p>Kwota dofinansowania to 100 mln zł.</p>
            </div>
            <div class="col-12 col-lg-6 d-flex align-items-center">
                <div class="row pt-3">
                    <div class="col">
                        <img src="img/BELKA_FunduszeEU.svg" alt="Fundusze Europejskie">
                    </div>
                    <div class="col">
                        <img src="img/BELKA_RP.svg" alt="Rzeczpospolita Polska">
                    </div>
                    <div class="col">
                        <img src="img/BELKAPodkarpackie.svg" alt="Podkarpackie">
                    </div>
                    <div class="col">
                        <img src="img/BELKA_UE.svg" alt="Unia Europejska">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a id="backtotop" class=""><span class="fas fa-arrow-up fa-2x"></span></a>
    <div class="acess-container">
        <div id="jbbutton" title="Ułatwienia dostępu" style="display: block;"><img src="img/acessc50.png" alt="WCAG" id="wcag"></div>
        <div id="acess-icons" style="display: none;">
            <div class="acess-icon"><img id="contrast" title="Kontrast" src="img/contraste40.png" alt="Kontrast"></div>
            <div class="acess-icon"><img id="increaseFont" title="Zwiększ czionkę" src="img/fontsma40.png" alt="Zwiększ czionkę"></div>
            <div class="acess-icon"><img id="decreaseFont" title="Zmniejsz czionkę" src="img/fontsme40.png" alt="Zmniejsz czionkę"></div>
        </div>
    </div>
</template>

<script>
export default{
    name: "FnibFooter",
    mounted() {
    // let newscript = document.createElement("script");
    // newscript.setAttribute("src", "js/script.js");
    // document.head.appendChild(newscript);

    const newscript = document.createElement("script");
    newscript.setAttribute("src", "js/script.js");
    newscript.async = true;
    document.head.appendChild(newscript);

  }
}
</script>