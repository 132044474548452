<template>
<div class="bg_header">
  <section class="header_menu bg-white" style="position: fixed; width: 100%; top: 0; height: 50px; z-index: 1080;">
    <div class="container menu">
      <div class="row align-items-center" style="display: flex; justify-content: center;">
        <div class="col order">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border:1px solid black">
              <span class="navbar-toggler-icon">
                <i class="fas fa-bars fa-1x"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto" style="justify-content: center;">
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/" class="nav-link">FNiB2</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link :to="{ path: '/',hash:'#idea'}" class="nav-link">Idea</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_forum" class="nav-link">Prelegenci Forum</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_ipa" class="nav-link">Prelegenci I-P-A</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link :to="{ path: '/',hash:'#agenda'}" class="nav-link">Agenda</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link :to="{ path: '/',hash:'#form_getresponse'}" class="nav-link">Rejestracja</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
    <div>
    <div class="container top_banner" style="display:grid;">
      <div class="row mt-3 mt-lg-5 pt-5" style="margin-top: 50px!important;">
        <div class="col-6 text-center">
          <img src="img/PFNIB_BW.svg" class="logo_pfnib"/>
        </div>
        <div class="col-6 text-center">
          <img src="img/23-24.svg" class="date_pfnib" />
        </div>
      </div>
      <div class="row mt-4 mt-lg-5 pt-5">
        <div class="col text-center mb-5 mb-md-0">
          <img src="img/Człowiek_dla_tech.svg" class="text_pfnib" />
        </div>
      </div>
      <div class="row d-none d-md-block">
        <div class="col text-center py-5">
          <p class="font-weight-bold">Forum Nauki i Biznesu jest dedykowane przedsiębiorcom, naukowcom i innowatorom.<br/> Organizator - Podkarpackie Centrum Innowacji.</p>
          <p class="mt-5 register_button">
          </p>
        </div>
      </div>
    </div>
       
  </div>
</div>
<div class="container">
    <div class="row">
        <div class="col">
                <h2 class="my-4">Informacja o przetwarzaniu danych osobowych dla uczestników wydarzeń PCI</h2>
                <p>
                    Wykonując obowiązek zgodnie z art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (dalej „RODO”) administrator danych osobowych informuje, a osoba, której dane dotyczą potwierdza przyjęcie do wiadomości następujących informacji:
                </p>
                <p>
                    <strong>Administratorem danych osobowych jest Podkarpackie Centrum Innowacji Sp. z o.o. z siedzibą w Rzeszowie</strong>, ul. Teofila Lenartowicza 4, 35 – 051 Rzeszów, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Rzeszowie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego Nr KRS: 0000710883, NIP: 8133765154, REGON: 368953574.
                </p>
                <p>
                    <stronh>PCI wyznaczył Inspektora Ochrony Danych (IOD)</stronh>, z którym Pani/Pan może się skontaktować w sprawie przetwarzania swoich danych osobowych. Kontakt z IOD poprzez mail: <a href="mailto:iod@pcinn.org">iod@pcinn.org</a> lub kom. +48 798 870 478
                </p>
                <p><u>
                    Pod ww. dane można się kontaktować w każdej sprawie związanej z przetwarzaniem danych osobowych przez PCI, czy to jako Administrator danych czy Podmiot Przetwarzający.
                </u></p>
                <p><strong>Dane osobowe w postaci:</strong></p>
                    <ol>
                        <li>Imię</li>
                        <li>Nazwisko</li>
                        <li>Stanowisko</li>
                        <li>Adres e-mail</li>
                    </ol>
                <p><strong>będą przetwarzane przez PCI w celu:</strong> (1) organizacji i przeprowadzeniu eventu/wydarzenia (art. 6 ust. 1 lit. a) - zgoda), (2) udostępnienie danych osobowych organizatorowi/organizatorom eventu/wydarzenia w celach administracyjnych (np. poinformowanie o miejscu i czasie eventu/wydarzenia) - <u>dotyczy sytuacji, gdy wydarzenie organizowane jest przez inny podmiot niż PCI</u>, (3) marketing bezpośredni produktów i usług własnych PCI (np. wysyłka newslettera, informowanie o wydarzeniach organizowanych przez PCI) - <u>dotyczy wyłącznie sytuacji, kiedy osoba, której dane dotyczą, wyrazi zgodę na otrzymywanie informacji handlowej drogą elektroniczną lub komunikację telefoniczną w celach marketingowych</u>, (4) ustalenie, dochodzenie oraz obrona przed roszczeniami. Dane są zbierane również do celów statystycznych.
                </p>
                <p><strong>Dane osobowe mogą być przekazywane:</strong></p>
                    <ol>
                        <li>organizatorowi lub organizatorom wydarzenia (np. uczelni wyższej) - dotyczy sytuacji, gdy event/wydarzenie organizowane lub współorganizowane jest przez inny podmiot niż PCI;</li>
                        <li>podmiotom świadczącym na rzecz administratora usługi lub dostarczające towary lub informacje w tym usługi IT, (np. przetwarzanie danych, zarządzanie zasobami, utrzymanie elektronicznego formularza zgłoszenia uczestnictwa), w zakresie w jakim ma to zastosowanie do działań związanych z organizacją i przeprowadzeniem eventu/wydarzenia przez administratora;</li>
                        <li>podmiotom upoważnionym przez przepisy prawa np. organy nadzoru, organy ścigania czy sądy,</li>
                        <li>podmiotom nadzorującym działania PCI.</li>
                    </ol>
                <p>
                   Dane osobowe nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.
                </p>
                <p><strong>Dane osobowe będą przetwarzane przez PCI do czasu:</strong></p>
                    <ol>
                        <li>zakończenia eventu/wydarzenia lub wycofania zgody na przetwarzanie danych osobowych;</li>
                        <li>rezygnacji z otrzymywania newslettera lub komunikacji marketingowej drogą telefoniczną - <u>dotyczy wyłącznie sytuacji, gdy osoba, której dane dotyczą, wyraziła zgodę na wymienione wyżej działania;</u></li>
                    </ol>
                <p>
                    Po upływie powyższych okresów administrator będzie przechowywał dane osobowe, jeżeli zobowiązany jest do tego na mocy przepisów prawa przez okres przewidziany w tych przepisach lub w celu realizacji uzasadnionych interesów przez okres przedawnienia roszczeń.
                </p>
                <p><strong>W związku z przetwarzaniem danych osobowych uczestnikowi eventu/wydarzenia przysługują następujące prawa:</strong></p>
                    <ol>
                        <li>dostępu do danych osobowych, do sprostowania danych osobowych (aktualizacji), do usunięcia danych, do ograniczenia przetwarzania, do przenoszenia danych oraz do wniesienia sprzeciwu wobec przetwarzania danych osobowych;</li>
                        <li>prawo do wycofania zgody na przetwarzanie danych osobowych - zgodę można wycofać poprzez wysłanie wiadomości na adres e-mail: iod@pcinn.org;</li>
                        <li>do wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).</li>
                    </ol>
                <p>
                    Podanie danych osobowych jest dobrowolne, jednak bez ich podania nie będzie możliwe uczestnictwa w evencie/wydarzeniu. Podanie danych osobowych w pozostałym zakresie jest dobrowolne.
                </p>
            </div>
    </div>
</div>
</template>

<script>
export default {
  name: "Rodo_events",
};
</script>