<template>
<FnibHeader />
  <section class="chessboard d-none d-lg-flex" id="idea">
    <div class="chessboard_lem">
      <div class="row mx-0">
        <!-- <div class="col">
          <img src="img/1.svg" />
        </div> -->
        <div class="col d-lg-none d-xl-block">
          <img src="img/2.svg" />
        </div>
        <div class="col d-lg-none d-xl-block">
          <img src="img/3.svg" />
        </div>
        <div class="col">
          <img src="img/4.svg" />
        </div>
        <div class="col">
          <img src="img/5.svg" />
        </div>
        <div class="col">
          <img src="img/6.svg" />
        </div>
        <div class="col">
          <img src="img/7.png" />
        </div>
      </div>
      <div class="row mx-0">
        <!-- <div class="col">
          <img src="img/8.svg" />
        </div> -->
        <div class="col d-lg-none d-xl-block">
          <img src="img/9.svg" />
        </div>
        <div class="col d-lg-none d-xl-block">
          <img src="img/10.svg" />
        </div>
        <div class="col">
          <img src="img/11.png" />
        </div>
        <div class="col">
          <img src="img/12.svg" />
        </div>
        <div class="col">
          <a href="https://roklema.pl/" target="_blank">
            <img src="img/13.svg" />
          </a>
        </div>
        <div class="col">
          <a href="https://roklema.pl/" target="_blank">
            <img src="img/14.svg" />
          </a>
        </div>
      </div>
      <div class="row mx-0">
        <!-- <div class="col">
          <img src="img/15.svg" />
        </div> -->
        <div class="col d-lg-none d-xl-block">
          <img src="img/16.svg" />
        </div>
        <div class="col d-lg-none d-xl-block">
          <img src="img/17.svg" />
        </div>
        <div class="col">
          <img src="img/18.svg" />
        </div>
        <div class="col">
          <img src="img/19.svg" />
        </div>
        <div class="col">
          <a href="https://roklema.pl/" target="_blank">
            <img src="img/20.svg" />
          </a>
        </div>
        <div class="col">
          <a href="https://roklema.pl/" target="_blank">
            <img src="img/21.svg" />
          </a>
        </div>
      </div>
    </div>
    <div class="lem_text">
      <div class="container">
        <div class="row">
          <div class="col-7 idea_tekst pl-4">
            <h3 class="font_montserrat_bold">Jak traktować roboty, które wkrótce staną się naszą codziennością?</h3>
            <p>- Czy sztuczna inteligencja jest zagrożeniem dla człowieka?</p>
            <p>- Czym jest inżynieria intymności i roboty opiekuńcze?</p>
            <p>- Dlaczego tak ważny jest rozwój etyki w technologii?</p>
            <p>Odpowiedzi na te i wiele inn ych pytań poznasz biorąc udział w II edycji Forum Nauki i Biznesu. To wydarzenie, które wspiera współpracę przedsiębiorców i nauk owców pod kątem kreowania innowacji.</p>
            <p class="mb-0 mt-5 agenda_button">
              <a href="#agenda">
                <button>
                  SPRAWDŹ AGENDĘ >
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="d-block d-lg-none mt-4">
  <!-- <section class="d-none"> -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="font_montserrat_bold">Jak traktować roboty, które wkrótce staną się naszą codziennością?</h3>
            <p>- Czy sztuczna inteligencja jest zagrożeniem dla człowieka?</p>
            <p>- Czym jest inżynieria intymności i roboty opiekuńcze?</p>
            <p>- Dlaczego tak ważny jest rozwój etyki w technologii?</p>
            <p>Odpowiedzi na te i wiele innych pytań poznasz biorąc udział w II edycji Forum Nauki i Biznesu. To wydarzenie, które wspiera współpracę przedsiębiorców i naukowców pod kątem kreowania innowacji.</p>
            <p class="mb-4 mt-5 text-center">
              <a href="#agenda">
                <button>
                  SPRAWDŹ AGENDĘ >
                </button>
              </a>
            </p>
        </div>
      </div>
    </div>
  </section>
  <div class="container my-5 d-none d-lg-block">
    <div class="row">
      <div class="col-6">
        <h3 class="text-left font_montserrat_bold mb-5" style="font-size: 1.5vw;">23 listopada<br/>Forum Nauka i Biznes</h3>
        <p class="text-justify pr-lg-5">Pierwszy dzień to Forum Nauka i Biznes, podczas którego poruszone zostaną tematy związane m.in. z etycznymi aspektami rozwoju technologii. Do udziału w pierwszym dniu wydarzenia zaprosiliśmy ekspertów i praktyków, którzy postarają się odpowiedzieć na pytanie będące motywem przewodnim wydarzenia: “Człowiek dla technologii, czy technologia dla człowieka?”</p>
      </div>
      <div class="col-6">
        <h3 class="text-right font_montserrat_bold mb-5" style="font-size: 1.5vw;">24 listopada - IV Konferencja<br/>Innowacje - Pomiary – Akredytacje</h3>
        <p class="text-justify">Drugi dzień to Konferencja „Innowacje - Pomiary - Akredytacje” skierowana do przedstawicieli laboratoriów badawczych, wzorcujących, akredytowanych i nieakredytowanych oraz przedstawicieli firm zlecających badania swoich wyrobów lub wzorcowania aparatury pomiarowej. Tegoroczna edcja odbędzie się pod hasłem: „Kompetencje laboratoriów wobec wymagań zleceniodawców badań”.</p>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-6" style="padding: 1% 2%; background-color: #b6db09;">
            <Prelegents_home_forum/>
          </div>
          <div class="col-6" style="padding: 1% 2%; background-color: rgb(245 245 245);">
            <Prelegents_home_ipa/>
          </div>
        </div>
        <div class="row mt-3">
        <div class="col-6 text-center">
          <u><a href="/prelegents_forum" class="nav-link">PRELEGENCI FNIB ></a></u>
        </div>
        <div class="col-6 text-center">
          <u><a href="/prelegents_ipa" class="nav-link">PRELEGENCI I-P-A ></a></u>
        </div>
      </div>
      </div>
    </div>
  </div>

  <div class="chessboard_lem w-100 d-block d-lg-none">
    <div class="row mx-0">
      <div class="col">
        <img src="img/5.svg" />
      </div>
      <div class="col">
        <img src="img/6.svg" />
      </div>
      <div class="col">
        <img src="img/7.png" />
      </div>
      <div class="col">
        <img src="img/4.svg" />
      </div>
    </div>
    <div class="row mx-0">
      <div class="col">
        <img src="img/12.svg" />
      </div>
      <div class="col">
        <a href="https://roklema.pl/" target="_blank">
          <img src="img/13.svg" />
        </a>
      </div>
      <div class="col">
        <a href="https://roklema.pl/" target="_blank">
          <img src="img/14.svg" />
        </a>
      </div>
      <div class="col">
        <img src="img/11.png" />
      </div>
    </div>
    <div class="row mx-0">
      
      <div class="col">
        <img src="img/19.svg" />
      </div>
      <div class="col">
        <a href="https://roklema.pl/" target="_blank">
          <img src="img/20.svg" />
        </a>
      </div>
      <div class="col">
        <a href="https://roklema.pl/" target="_blank">
          <img src="img/21.svg" />
        </a>
      </div>
      <div class="col">
        <img src="img/18.svg" />
      </div>
    </div>
  </div>

  <div class="container my-5 d-block d-lg-none">
    <div class="row">
      <div class="col-12">
        <h3 class="text-center font_montserrat_bold mb-5">23 listopada<br/>Forum Nauka i Biznes</h3>
        <p class="text-justify pr-lg-5">Pierwszy dzień to Forum Nauka i Biznes, podczas którego poruszone zostaną tematy związane m.in. z etycznymi aspektami rozwoju technologii. Do udziału w pierwszym dniu wydarzenia zaprosiliśmy ekspertów i praktyków, którzy postarają się odpowiedzieć na pytanie będące motywem przewodnim wydarzenia: “Człowiek dla technologii, czy technologia dla człowieka?”</p>
        <div class="container" style="padding: 1% 2%; background-color: #b6db09;">
              <Prelegents_home_forum/>
        </div>
        <div class="col-12 text-center prelegents_link">
          <u><a href="/prelegents_forum" class="nav-link">PRELEGENCI FNIB ></a></u>
        </div>
      </div>
      <div class="col-12 mt-5">
        <h3 class="text-center font_montserrat_bold mb-5">24 listopada - IV Konferencja<br/>Innowacje - Pomiary – Akredytacje</h3>
        <p class="text-justify">Drugi dzień to Konferencja „Innowacje Pomiary Akredytacje” skierowana do przedstawicieli laboratoriów badawczych, wzorcujących, akredytowanych i nieakredytowanych oraz przedstawicieli firm zlecających badania swoich wyrobów lub wzorcowania aparatury pomiarowej. Tegoroczna edcja odbędzie się pod hasłem: „Kompetencje laboratoriów wobec wymagań zleceniodawców badań”.</p>
        <div class="container" style="padding: 1% 2%; background-color: rgb(245 245 245);">
              <Prelegents_home_ipa/>
        </div>
        <div class="col-12 text-center prelegents_link">
          <u><a href="/prelegents_ipa" class="nav-link">PRELEGENCI I-P-A ></a></u>
        </div>
      </div>
    </div>
  </div>
  <div id="agenda">
    <Agenda />
  </div>
  <!-- <div id="form_getresponse">
    <FormFNiB />
  </div> -->
</template>

<script>
// import FormFNiB from "./FormFNiB.vue";
import Agenda from "./Agenda.vue";
import FnibHeader from "./FnibHeader.vue";
import Prelegents_home_forum from"./Prelegents_home_forum.vue";
import Prelegents_home_ipa from"./Prelegents_home_ipa.vue";

export default {
  name: "Home",
  components: {
    FnibHeader,
    // FormFNiB,
    Prelegents_home_forum,
    Prelegents_home_ipa,
    Agenda,
  },
};
</script>