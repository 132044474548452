<template>
  <div>
    <div class="row agenda-row" v-for="(d, index) in dataMap" :key="index">
      <div class="col-12 col-lg-2 grey p-3 circle">
        <p class="time">
          {{ date(d[0].start.dateTime) }} - {{ date(d[0].end.dateTime)}}
        </p>
      </div>
      <div class="col-12 col-lg-10">
        <div class="row">
          <div class="col-12 text-center row-border">
            <h3 class="agenda-title">{{ d[0].subject }}</h3>
            <hr />
            <div class="row justify-content-center" v-for="(m, index) in d" :key="index" >
              <div
                class="col-4" v-for="prelegents in m.eventAttendees" :key="prelegents" >
                <span class="prelegent-name">{{ prelegents.displayName }}</span>
                <p class="prelegent-position">
                  {{ prelegents.jobPosition }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Day1track2",
  data() {
    return {
      prelegents: [],
      eventsdate: [],
      dataMap: [],
      // res: [],
      start1: 'sciezka2',
    };
  },
  mounted() {
    this.loadCategories();
    this.loadProducts();
  },
  methods: {

    loadCategories: function () {
      axios
        .get("/api/api/events/fnib-attenders", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.listDataString = JSON.stringify(response.data, null, "\t");
          this.prelegents = response.data.result;
          
          this.fillEvents();
          return response;
        })
        .catch(function (error) {
          console.log(error);
        }); 
    },
    loadProducts: function () {
      axios
        .get("/api/api/events/fnib-calendar?start=2021-10-01&end=2021-12-31", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.listDataString = JSON.stringify(response.data, null, "\t");
        // this.eventsdate  = response.data.result;  //to tu
         this.eventsdate  = response.data.result.filter(eventsdate => !eventsdate.categories.indexOf(this.start1));
          // var res = this.prelegentstest.map(obj => this.eventsdate.find(o => o.id === obj.id) || obj);
          // console.log(res);

          // var n = this.eventsdate;
          // var m = this.prelegentstest;
          // n.forEach(i=>{
          //   if (n.eventAttendees = m.mail)
          //   return n.eventAttendees = "asdasd";
          // });
          
          this.fillEvents();
         
        //  var elements = this.eventsdate;
        //   if(elements)
        //   {
        //     var len = elements.lenght;
        //     for (var i = 0; i< len; i++)
        //     {
        //         var event = elements[i];
        //           for (var j = 0; j< elements.eventAttendees.lenght; j++)
        //             {                    
        //                 var eventAttende = event.eventAttendees[j];                 

        //                 var filteredPrelegent = this.prelegents.filter(this.isSame(eventAttende))
        //                 console.log(filteredPrelegent);
        //             }           

        //     }
        //   }
          
          let uniq_dates = Array.from(
            new Set(this.eventsdate.map((obj) => obj.start.dateTime))
          );
          let date_filtered;

          uniq_dates.filter((data) => {
            let key = data;
            date_filtered = this.eventsdate.filter((item) => {
              if (key == item.start.dateTime) {
                return item;
              }
            });
            this.dataMap.push(date_filtered);           
          });
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    date: function (date) {
      return moment(date).add(1, 'hours').lang("pl").format("LT");
    },
    dateday: function (dateday) {
      return moment(dateday).lang("pl").format("dddd");
    },
    filterByDate: function(){
      return this.dataMap.filter(dataMap => !dataMap.start.dateTime.indexOf(this.start1))
    },
    fillEvents: function(){
        this.eventsdate.forEach(event => {  
                      event.eventAttendees.forEach(attendee => {
                      var filteredPrelegent = this.prelegents.filter(this.isSame(attendee))
                      if(filteredPrelegent && filteredPrelegent[0])
                      {
                        attendee.displayName=filteredPrelegent[0].name;
                        attendee.jobPosition=filteredPrelegent[0].jobPosition;
                      }
                      else
                      {
                        // console.log(attendee.email);
                          attendee.displayName= "TBA";
                          attendee.jobPosition= "";
                      }
                  })
                  });
    },
     isSame: function(atendee){
                         return function(element) {
                            return element.mail === atendee.email;
                        }
                      },
  },
};
</script>