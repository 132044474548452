<template>
<div class="bg_header">
  <section class="header_menu bg-white" style="position: fixed; width: 100%; top: 0; max-height: 50px; z-index: 1080;">
    <div class="container menu">
      <div class="row align-items-center" style="display: flex; justify-content: center;">
        <div class="col order">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border:1px solid black">
              <span class="navbar-toggler-icon">
                <i class="fas fa-bars fa-1x"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto" style="justify-content: center;">
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/" class="nav-link">FNiB2</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <a href="#idea" class="nav-link">Idea</a>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <a href="/prelegents_forum" class="nav-link">Prelegenci Forum</a>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <a href="/prelegents_ipa" class="nav-link">Prelegenci I-P-A</a>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/#agenda" class="nav-link">Agenda</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <div>
    <div class="container top_banner" style="display:grid;">
      <div class="row mt-3 mt-lg-5 pt-5" style="margin-top: 50px!important;">
        <div class="col-6 text-center">
          <img src="img/PFNIB_BW.svg" class="logo_pfnib"/>
        </div>
        <div class="col-6 text-center">
          <img src="img/23-24.svg" class="date_pfnib" />
        </div>
      </div>
      <div class="row mt-4 mt-lg-5 pt-5">
        <div class="col text-center mb-5 mb-md-0">
          <img src="img/Człowiek_dla_tech.svg" class="text_pfnib" />
        </div>
      </div>
      <div class="row d-none d-md-block">
        <div class="col text-center py-5">
          <p class="font-weight-bold">Forum Nauki i Biznesu jest dedykowane przedsiębiorcom, naukowcom i innowatorom.<br/> Organizator - Podkarpackie Centrum Innowacji.</p>
        </div>
      </div>
    </div>
       
  </div>
</div>
</template>

<style scoped>
.nav-item{
  padding: 0 1%;
}
</style>

<script>
export default {
  name: "FnibHeader",
};
</script>