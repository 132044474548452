import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Prelegents_forum from "@/views/Prelegents_forum.vue";
import Prelegents_ipa from "@/views/Prelegents_ipa.vue";
import Success from "@/views/Success.vue";
import Agendatest from "@/views/Agendatest.vue";
import Rodo_events from "@/views/Rodo_events.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home
    },
    meta: { title: 'FNiB - Strona główna' },
    children: [
      { 
        path: 'home/#idea',
      },
      {
        path: 'home/#form_getresponse',
      },
      {
        path: 'home/#agenda',
      }
    ]
  },
  {
    path: "/Prelegents_forum",
    name: "Prelegents Forum",
    components: {
      default: Prelegents_forum,
    },
    meta: { title: 'FNiB - Prelegenci Forum' },
  },
  {
    path: "/Prelegents_ipa",
    name: "Prelegents I-P-A",
    components: {
      default: Prelegents_ipa,
    },
    meta: { title: 'FNiB - Prelegenci I-P-A' }
  },
  {
    path: "/Success",
    name: "Success",
    components: {
      default: Success,
    },
    meta: { title: 'FNiB - Success' }
  },
  {
    path: "/Agendatest",
    name: "Agendatest",
    components: {
      default: Agendatest,
    },
    meta: { title: 'FNiB - Agendatest' }
  },
  {
    path: "/rodo-events",
    name: "Rodo_events",
    components: {
      default: Rodo_events,
    },
    meta: { title: 'FNiB - Rodo' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router;