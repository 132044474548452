<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="col-12">
                    <h3 class="text-center font_montserrat_bold mb-5 mt-4 title-h3">Agenda</h3>
                </div>
                <ul class="nav nav-tabs nav-justified agenda_tabs">
                    <li class="nav-item nav_item_track1">
                        <a class="nav-link" @click.prevent="setActive('track1')" :class="{ active: isActive('track1') }" data-target="#track1">fnib - ścieżka 1<br/>23.11.2021</a>
                    </li>
                    <li class="nav-item nav_item_track2">
                        <a class="nav-link" @click.prevent="setActive('track2')" :class="{ active: isActive('track2') }" data-target="#track2">fnib - ścieżka 2<br/>23.11.2021</a>
                    </li>
                    <li class="nav-item nav_item_track3">
                        <a class="nav-link" @click.prevent="setActive('track3')" :class="{ active: isActive('track3') }" data-target="#track3">fnib - ścieżka 3<br/>23.11.2021</a>
                    </li>
                    <li class="nav-item nav_item_track4">
                        <a class="nav-link" @click.prevent="setActive('track4')" :class="{ active: isActive('track4') }" data-target="#track4">I-p-a <br/> 24.11.2021</a>
                    </li>
                </ul>
                <div class="tab-content py-3" id="myTabContent">
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track1') }" id="track1">
                        <Day1track1   />
                        <!-- <sing track=activeItem/> -->
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track2') }" id="track2">
                        <Day1track2 />
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track3') }" id="track3">
                        <Day1track3 />
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track4') }" id="track4">
                        <Day2track1 />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Day1track1 from "./agendatrack/Day1track1.vue";
import Day1track2 from "./agendatrack/Day1track2.vue";
import Day1track3 from "./agendatrack/Day1track3.vue";
import Day2track1 from "./agendatrack/Day2track1.vue";

export default {
  name: "Agenda",
  components: {
    Day1track1,
    Day1track2,
    Day1track3,
    Day2track1
  },
  data() {
    return {
      eventsdate: [],
      activeItem: 'track1',
      sciezka1: 'sciezka1',
      sciezka2: 'sciezka2',
    };
  },
  methods: {
        isActive (menuItem) {
            return this.activeItem === menuItem
        },
        setActive (menuItem) {
            this.activeItem = menuItem
        },
  },
  
};
</script>