<template>
    <div class="bg_header">
  <section class="header_menu bg-white" style="position: fixed; width: 100%; top: 0; height: 50px; z-index: 1080;">
    <div class="container menu">
      <div class="row align-items-center" style="display: flex; justify-content: center;">
        <div class="col order">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border:1px solid black">
              <span class="navbar-toggler-icon">
                <i class="fas fa-bars fa-1x"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto" style="justify-content: center;">
                <li class="nav-item d-flex align-items-center">
                  <!-- <router-link to="/" class="nav-link">FNiB2</router-link> -->
                  <router-link to="home/#idea" class="nav-link">FNiB2</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <!-- <router-link :to="{ path: '/',id:'idea'}" class="nav-link">Idea</router-link> -->
                  <a href="#idea" class="nav-link">Idea</a>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_forum" class="nav-link">Prelegenci Forum</router-link>
                </li>
                <!-- <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_ipa" class="nav-link">Prelegenci I-P-A</router-link>
                </li> -->
                <!-- <li class="nav-item d-flex align-items-center">
                  <router-link to="/#agenda" class="nav-link">Agenda</router-link>
                </li> -->
                <li class="nav-item d-flex align-items-center">
                  <router-link to="home/#getresponse" class="nav-link">Rejestracja</router-link>
                  <!-- <a href="#getresponse" class="nav-link">Rejestracja</a> -->
                </li>
                <!-- <li class="nav-item d-flex align-items-center">
                  <a href="#" class="nav-link">Poprzednia edycja</a>
                </li> -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="bg_header" style="background-image: url(../img/prelegenci_forum.svg); margin-top:50px;">
    <div class="container " style="display:grid;">
      <div class="row py-4">
        <div class="col-6 text-center">
          <img src="img/PFNIB_BW.svg" class="logo_pfnib" style="width: 40%;"/>
        </div>
        <div class="col-6 text-center">
          <img src="img/23-24.svg" class="date_pfnib" style="width: 50%"/>
        </div>
      </div>
    </div>
</div>
<div class="container">
    <div class="row mt-5">
        <div class="col">
            <h1 class="text-center">Dziękujemy <span class="font_montserrat_bold">za wypełnienie</span><br/><ins class="font_montserrat_bold text-decoration-underline">formularza!</ins></h1>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: "Success",
};
</script>