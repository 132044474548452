<template>
<div class="bg_header">
  <section class="header_menu bg-white" style="position: fixed; width: 100%; top: 0; height: 50px; z-index: 1080;">
    <div class="container menu">
      <div class="row align-items-center" style="display: flex; justify-content: center;">
        <div class="col order">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="border:1px solid black">
              <span class="navbar-toggler-icon">
                <i class="fas fa-bars fa-1x"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto" style="justify-content: center;">
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/" class="nav-link">FNiB2</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link :to="{ path: '/',hash:'#idea'}" class="nav-link">Idea</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_forum" class="nav-link">Prelegenci Forum</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link to="/prelegents_ipa" class="nav-link">Prelegenci I-P-A</router-link>
                </li>
                <li class="nav-item d-flex align-items-center">
                  <router-link :to="{ path: '/',hash:'#agenda'}" class="nav-link">Agenda</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="bg_header" style="background-image: url(../img/prelegenci_forum.svg); margin-top:50px;">
    <div class="container " style="display:grid;">
      <div class="row py-4">
        <div class="col-6 text-left prelegents_subtitle">
          <p class="mb-0">FNiB</p>
          <p class="mb-0"><span>PRELEGENCI</span></p>
          <p class="mb-0">23.11.2021</p>
        </div>
        <div class="col-6 text-center" style="display: flex;justify-content: center;align-items: center;">
          <p class="mb-0">
            <router-link to="/prelegents_ipa" class="nav-link">
              <button class="prelegents_title_forum">PRELEGENCI KONFERENCJA I-P-A ></button>
            </router-link>
          </p>
        </div>
      </div>
    </div>
</div>
<div class="container mt-5">
  <div class="row">
    <div class="col-6 col-lg-3 text-center" v-for="id in filterPrelegentsByCategory" :key="id.id">
      <div>
        <img :src="id.image" alt="Prelegent" data-aos="flip-left" class="tooltip aos-init aos-animate" :title="id.description">
        <p class="prelegents_name">{{id.name}}</p>
        <p class="prelegents_position">{{id.jobPosition}}</p>
      </div>
    </div>
  </div>
</div>
</template>

<style>
.tooltip{
  position: inherit;
  opacity: 1;
}
.nav-item{
  padding: 0 1%;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Prelegents_forum",
  data() {
    return {
      prelegents: [],
      category: 'prelegentpfnib',
      category2: 'prelegentpfnibglowna',
    };
  },
  mounted() {
    axios
      .get(
        "/api/api/events/fnib-attenders",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.listDataString = JSON.stringify(response.data, null, "\t");
        this.prelegents = response.data.result;
        // console.log(response);
        return response;
      });
      
      const tooltipscript = document.createElement("script");
    tooltipscript.setAttribute("src", "js/tooltip.js");
    tooltipscript.async = true;
    document.head.appendChild(tooltipscript);
  },
  computed: {
    filterPrelegentsByCategory: function(){
      return this.prelegents.filter(prelegents => !prelegents.categories.indexOf(this.category) || !prelegents.categories.indexOf(this.category2))
    }
  }
};
</script>