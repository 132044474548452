<template>
  <div class="row prelegents_home">
    <div class="col-3 text-center prelegents_data" v-for="item in prelegentsiec" :key="item.start">
      <img :src="item.image" alt="Prelegent" data-aos="flip-left" class="tooltip aos-init aos-animate">
      <p class="prelegents_name">{{item.name}}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Prelegents_home_ipa",
  data() {
    return {
      prelegents: [],
      category: 'prelegentsiecglowna',
      category2: 'prelegentpfnibglowna',
    };
  },
  mounted() {
    axios
      .get(
        "/api/api/events/fnib-attenders",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.listDataString = JSON.stringify(response.data, null, "\t");
        this.prelegents = response.data.result;
        return response;
      });
  },
  computed: {
    prelegentsiec: function(){
      return this.prelegents.filter(prelegents => !prelegents.categories.indexOf(this.category))
    },
    prelegentpfnib: function(){
      return this.prelegents.filter(prelegents => !prelegents.categories.indexOf(this.category2))
    }
  }
};
</script>
