<template>
  <!-- <FnibHeader></FnibHeader> -->
  <router-view />
  <FnibFooter></FnibFooter>
</template>


<script>
// import FnibHeader from "./views/FnibHeader.vue"
import FnibFooter from "./views/FnibFooter.vue"

export default {
    components: {
        // FnibHeader,
        FnibFooter
    }
}
</script>

