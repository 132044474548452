<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="nav nav-tabs nav-justified">
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="setActive('track1')" :class="{ active: isActive('track1') }" href="#track1">Ścieżka 1</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="setActive('track2')" :class="{ active: isActive('track2') }" href="#track2">Ścieżka 2</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="setActive('track3')" :class="{ active: isActive('track3') }" href="#track3">Ścieżka 3</a>
                    </li>
                </ul>
                <div class="tab-content py-3" id="myTabContent">
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track1') }" id="track1">
                        <div class="row">
                            <div class="col-lg-2 grey p-3" style="display: flex;align-items: center;justify-content: center;">
                                <p class="time" style="background-color: #000;color: #fff;padding: 25% 18%;border-radius: 100%;">9-9:30</p>
                            </div>
                            <div class="col-10">
                                <div class="row agenda_row">
                                    <div class="col-12 text-center" v-for="item in day1hour90931" :key="item.id">
                                        <h3>{{item.title}}</h3>
                                        <hr/>
                                        <div class="row">
                                            <div v-for="prelegents in item.prelegents" :key="prelegents" class="col-4">
                                                <span style="font-size:1vw;">{{prelegents.name}}</span>
                                                <!-- <p>{{prelegents.email}}</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track2') }" id="track2">Profile content</div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('track3') }" id="track3">Contact content</div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container mt-5">
        <div class="row">
            <h3>Sciezka1</h3>
            <div class="col-12 text-center" v-for="item in day1hour90931" :key="item.id">
                <h3>{{item.title}}</h3>
                <div class="row">
                    <div v-for="prelegents in item.prelegents" :key="prelegents" class="col-4 border">
                            <h4>{{prelegents.name}}</h4>
                            <!-- <p>{{prelegents.email}}</p> -->
                        </div>
                </div>
            </div>
        </div>
        <div class="row">
            <h3>Sciezka2</h3>
            <div class="col-6 col-lg-3 text-center" v-for="item in day1hour90932" :key="item.id">
                <h3>{{item.title}}</h3>
            <div>
                <div v-for="prelegents in item.prelegents" :key="prelegents">
                        <h4>{{prelegents.name}}</h4>
                        <!-- <p>{{prelegents.email}}</p> -->
                      </div>
            </div>
            </div>
        </div>
        </div>
        <pre>
            {{eventsdate}}
        </pre>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment'

export default {
  name: "Agendatest",
  data() {
    return {
      eventsdate: [],
      activeItem: 'track1',
      sciezka1: 'sciezka1',
      sciezka2: 'sciezka2',
      day1hour1: '2021-11-23T10:45:00.0000000',

    //   sciezka1: 'sciezka1',
    //   sciezka2: 'sciezka2',
    //   sciezka2: 'sciezka3',
    //   start845: '2021-11-23T08:45:00.0000000',
    //   start900: '2021-11-23T09:00:00.0000000',
    //   start935: '2021-11-23T09:35:00.0000000',
    //   start1010: '2021-11-23T10:10:00.0000000',
    //   start1045: '2021-11-23T10:45:00.0000000',
    //   start1055: '2021-11-23T10:55:00.0000000',
    //   start1135: '2021-11-23T11:35:00.0000000',
    //   start1110: '2021-11-23T11:10:00.0000000',
    //   start1145: '2021-11-23T11:45:00.0000000',
    //   start1220: '2021-11-23T12:20:00.0000000',
    //   start1255: '2021-11-23T12:55:00.0000000',
    //   start1330: '2021-11-23T13:30:00.0000000',
    //   start1405: '2021-11-23T14:05:00.0000000',

    };
  },
  mounted() {
    axios
      .get(
        "/api/api/events/fnib-calendar?start=2021-10-01&end=2021-12-31",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.listDataString = JSON.stringify(response.data, null, "\t");
        this.eventsdate = response.data.result.map((eventnew) => ({
          title: eventnew.subject,
          start: eventnew.start.dateTime,
          end: eventnew.end.dateTime,
          allDay: eventnew.isAllDay,
          url: eventnew.location,
          category: eventnew.categories,
          prelegents: eventnew.eventAttendees,
        }));
        console.log(this.eventsdate);
        return response;
      });
  },
  methods: {
      date: function (date) {
          return moment(date).format('h:mm a');
        },
        isActive (menuItem) {
            return this.activeItem === menuItem
        },
        setActive (menuItem) {
            this.activeItem = menuItem
        },
  },
    computed: {
        day1hour90931: function(){
        return this.eventsdate.filter(eventsdate => !eventsdate.start.indexOf(this.day1hour1) && !eventsdate.category.indexOf(this.sciezka1))
        },
        day1hour90932: function(){
        return this.eventsdate.filter(eventsdate => !eventsdate.start.indexOf(this.day1hour1) && !eventsdate.category.indexOf(this.sciezka2))
        }
    }
  
};


</script>